<template>
    <div class="authenticationPage" v-if="detailInfo">
        <div class="top-title" >个人实名认证</div>
        <div class="content" v-if="detailInfo.status == 1">
            <img class="img1" src="@/assets/toexamine-icon.png">
            <span class="span1" style="color: #0067E1;cursor: pointer;" @click="goQS">去签署</span>
            <span class="span1">恭喜您提交成功</span>
            <span class="span2">您的企业实名认证正在审核中～</span>
            <span class="span2">请耐心等待,1~3个工作日完成审核</span>
        </div>
        <!-- <div class="content" v-if="detailInfo.status == 0">
            <img class="img1" src="@/assets/toexamine-icon.png">
            <span class="span1">您的企业实名认证正在审核中～</span>
            <span class="span2">请耐心等待,1~3个工作日完成审核</span>
        </div> -->
        <div class="content2" v-if="detailInfo.status < 0">
            <img class="img1" src="@/assets/jujueshouquan.png">
            <span class="title1">由于如下原因,实名认证审核不通过</span>
            <div class="title2">
                <span>请核对</span>
                <span @click="relaShow()">重新认证</span>
            </div>
            <div class="reason">
                <span>驳回原因:{{detailInfo.reason}}</span>
            </div>
        </div>
        <PopList :showModel.sync="popBox.showModel" :popObj.sync="popBox.popObj" :list.sync="popBox.list" :fn="popBox.callbackFn"  :editFormData="popBox.editFormData"></PopList>
    </div>
</template>
<script>
import { Base64 } from 'js-base64'
import PopList from '@/views/components/popList/index.vue'
import { getRealNameInfoByLinkCode, hasRealnameExam } from '@/util/api'
import { mapState } from "vuex";
export default {
  data () {
    return{
      linkCode: null,
      detailInfo: null,
      popBox: { // 选择签署方式弹框
        showModel: false,
        type: 1,
        popObj:{
          labelName: 'name',
          valueName: 'id',
          list: []
        },
        list: [],
        callbackFn: () => {},
        editFormData: {}
      },
    }
  },
  components:{
    PopList
  },
  computed:{
    ...mapState(['userInfo',]),
  },
  created () {
    if(this.$route.query.linkCode){
      this.linkCode = this.$route.query.linkCode
      this.getInfo()
    }
  },
  methods: {
    goQS(){
    //   getSignatureToSignList().then(res =>{
    //     if(res){
    //         this.$store.commit('setSignList',res)
    //     }
    //   })
      this.$router.push({path: '/usePage',query:{}})
    },
    relaShow(){
      console.log('this.userInfo', this.userInfo)
      //判断是否实名认证了
      // if (!this.userInfo.realnameId) {//未认证
        hasRealnameExam({}).then((res)=>{//判断是否有审核中的认证
          // const { data, code } = res
          console.log('获取数据', res)
          if(res){
              this.$toast.fail("认证正在审核中,请耐心等待");
          }else{
            this.showPopList() // 展示选择方式
          }
         })
      // }
    },
    showPopList() {
      this.popBox.showModel = true
        this.popBox.list = [
          {id: 1, name: '手机号验证'},
          {id: 2, name: '人脸识别认证'},
        ]
      this.popBox.callbackFn = (val) => { // 回调处理
      if (val.id == 1){
        this.$router.push({ path: '/realName' })
      } else if(val.id == 2){
        let ttobj = {
          newPage: '1'
          // processSigns: [{ details: this.allSignData, processId: this.linkCodeInfo.processId }],
          // allSignData: this.allSignData,
          // signatureId: this.$route.query.signatureId
        }
        this.$router.push({ path: '/videoCertification', query:{ ttobj: Base64.encode(JSON.stringify(ttobj))} }) //  人脸实名认证
      }
        // 重新载入
        this.popBox.showModel = false
        this.popBox.popObj.list = []
        this.popBox.editFormData = {}
      }
    },
    goPage(name, data){
      this.$router.push({ path: name,query:{...data}})
    },
    getInfo(){
      getRealNameInfoByLinkCode({linkCode: this.linkCode}).then((res)=>{
        this.detailInfo = res
      })
    }
  }

}
</script>
<style lang="less" scoped>
.authenticationPage{
    width: 100%;
    height: 100%;
    background: #0067E1;
    display: flex;
    flex-direction: column;
    .top-title{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        color: #fff;
        justify-content: center;
        font-size: 17px;
    }
    .content{
        width: 100%;
        flex: 1;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 100px;
        .img1{
            width: 58px;
            height: 59px;
            margin-bottom: 10px;
        }
        .img2{
            width: 67px;
            height: 51px;
            margin-bottom: 10px;
        }
        .span1{
            font-size: 17px;
            color: #333;
            margin-bottom: 10px;
            font-weight: 700;
        }
        .span2{
            font-size: 16px;
            color: #A4A4A4;
            font-weight: 500;           
        }
        .info-flex1{
            width: 100%;
            display: flex;
            height: 30px;
            line-height: 30px;
            justify-content: flex-end;
            background: #fff;
            box-sizing: border-box;
            padding: 0 20px;
            span{
                text-decoration: underline;
                color: #0067E1;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
            }
        }
        .title {
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 17px;
            color: #30586C;
            padding: 0 20px;
            box-sizing: border-box;
            font-weight: bold;
        }
        .info-item1 {
            display: flex;
            width: 100%;
            padding: 0 20px;
            box-sizing: border-box;
            font-size: 15px;
            span {
                display: inline-block;
                min-width: 100px;
                color: #757B85;
                margin-right: 10px;
                &:nth-of-type(1){
                    text-align: right;
                }
                &:nth-of-type(2){
                    text-align: left;
                }
            }
        }
        .reason{
            width: 100%;
            display: flex;
            justify-content: center;
            span{
                width: 200px;
                font-size: 16px;
                padding: 10px 15px;
                min-height: 30px;
                background: #F5F5F5;
            }
        }
    }
    .content1{
        width: 100%;
        flex: 1;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 30px;
        box-sizing: border-box;
        .top-tit{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100px;
            font-size: 16px;
            img{
                width: 25px;
                height: 25px;
                margin-right: 10px;
            }
        }
        .center-box{
            width: 100%;
            height: 300px;
            border: 1px solid #dcdcdc;
            display: flex;
            flex-direction: column;
            font-size: 14px;
            .center-box-item1{
                width: 100%;
                height: 40px;
                line-height: 40px;
                padding-left: 20px;
                box-sizing: border-box;
            }
            .center-box-item2{
                width: 100%;
                height: 180px;
                display: flex;
                justify-content: center;
                img{
                    width: 180px;
                    height: 180px;
                }
            }
            .center-box-item3{
                width: 100%;
                flex: 1;
                align-items: center;
                color: #0067E1;
                cursor: pointer;
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;
                box-sizing: border-box;
            }
        }
    }
    .content2{
        width: 100%;
        flex: 1;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 30px;
        box-sizing: border-box;
        .img1{
            width: 58px;
            height: 59px;
            margin-bottom: 30px;
        }
        .title1{
            width: 100%;
            height: 30px;
            display: flex;
            justify-content: center;
            font-size: 16px;
            align-items: center;
        }
        .title2{
            width: 100%;
            height: 30px;
            display: flex;
            justify-content: center;
            font-size: 16px;
            span{
                &:nth-of-type(2){
                    color: #0067E1;
                    cursor: pointer;
                }
            }
        }
        .reason{
            width: 100%;
            display: flex;
            justify-content: center;
            span{
                width: 200px;
                font-size: 16px;
                padding: 10px 15px;
                min-height: 30px;
                background: #F5F5F5;
            }
        }
    }
    .popup-content{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .popup-content-title{
            width: 100%;
            height: 40px;
            font-size: 15px;
            text-align: center;
            line-height: 40px;
            border-bottom: 1px solid #dcdcdc;
        }
        .popup-content-top{
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            .popup-content-top-item{
                display: flex;
                padding: 20px;
                flex-direction: column;
                align-items: center;
                img{
                    width: 200px;
                    height: 200px;
                }
            }
        }
        .popup-content-btn{
            width: 100%;
            height: 70px;
            display: flex;
            justify-content: space-around;
            align-items: center;;
            box-sizing: border-box;
            border-top: 1px solid #dcdcdc;
            span {
                width: 120px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                font-size: 14px;
                border-radius: 25px;

                &:nth-of-type(1) {
                    color: #666666;
                    border: 1px solid #999999;
                }

                &:nth-of-type(2) {
                    color: #fff;
                    background: linear-gradient(90deg, #0067E1, #77A3FF);
                }
            }
        }
    }
    .info-flex{
        width: 100%;
        display: flex;
        height: 30px;
        line-height: 30px;
        justify-content: flex-end;
        span{
            text-decoration: underline;
            color: #0067E1;
            font-size: 14px;
            cursor: pointer;
        }
    }
}
</style>