<template>
  <div>
    <van-popup v-model="showModel" @click-overlay="close"  :position="position" :overlay="true"  :round='true'>
      <!-- <slot></slot> -->
      <div class="pop-box-cont">
        <div class="title">请选择以下方式进行实名认证</div>
        <div class="list" v-if="popObj">
          <div class="item" @click="checkItem(item)" :class="item.id == (itemObj && itemObj.id)? 'active':''" v-for="(item,index) in list" :key="index">{{item.name}}</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: [String],
      default: 'bottom',
    },
    showModel: Boolean,
    type: [Number, String],
    fn: Function,
    editFormData: [Object, Array],
    popObj:{
      type: Object,
    },
    list: [Array]
  },
  data () {
    return{
      itemObj: null
    }
  },
  methods: {
    checkItem(item){
      this.itemObj = item
      this.fn(this.itemObj)
    },
    close() {
      this.$emit('close', false)
      this.$emit('update:showModel', false)
    }
  }
}
</script>

<style lang="less" scoped>
  // /deep/ .van-popup {
  //   box-shadow: 0px -7px 15px rgba(65, 191, 232, 0.3);
  //   border-radius: 12px !important;
  // }
  .pop-box-cont{
    // height: 40vh;
    // padding-bottom: 40px;
    .title{
      padding: 13px 0px;
      box-sizing: border-box;
      font-size: 16px;
      text-align: center;
      background: #f5f5f5;
    }
    .list{
      // max-height: 32vh;
      overflow-y: auto;
      .item{
        text-align: center;
        display: flex;
        align-items: center;
        padding: 20px 0px;
        box-sizing: border-box;
        font-size: 14px;
        color: #333;
        border-bottom: 1px dashed #ccc;
        text-align: center;
        justify-content: center;
      }
      .active{
        color: #5B9CFE;
      }
    }
  }
</style>